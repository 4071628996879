import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { calculateResponsiveSize } from 'helpers/calculate-responsive-size'
import { mediaMax } from 'helpers/media-queries'

import fbIcon from '../../icons/facebookIcon.svg'
import instagramIcon from '../../icons/instagramIcon.svg'
import linkedinIcon from '../../icons/linkedinIcon.svg'
import twitterIcon from '../../icons/twitterIcon.svg'

export const FooterWrapper = styled.footer`
  background: var(--pureBlack);
  padding: ${calculateResponsiveSize(60)} ${calculateResponsiveSize(48)}
    ${calculateResponsiveSize(48)} ${calculateResponsiveSize(72)};
  margin-top: auto;
  display: flex;
  gap: ${calculateResponsiveSize(134)};
  color: white;
  font-family: 'Mark Pro';
  font-style: normal;
  ${mediaMax('mobile')} {
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 33px 24px;
    gap: 36px;
  }
  &.mobileContact {
    background: rgba(30, 30, 30, 0.9);
  }
`
export const Left = styled.div`
  width: ${calculateResponsiveSize(408)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${mediaMax('mobile')} {
    width: 100%;
    align-items: center;
  }
`
export const Title = styled.div`
  font-family: 'Two45 Park Display';
  font-style: normal;
  font-weight: 400;
  font-size: ${calculateResponsiveSize(72)};
  line-height: ${calculateResponsiveSize(72)};
  color: var(--brown);
  margin-bottom: ${calculateResponsiveSize(36)};
  letter-spacing: 0.05em;
  ${mediaMax('mobile')} {
    font-size: 60px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 36px;
  }
`
export const Logo = styled.div`
  margin-top: auto;
  ${mediaMax('mobile')} {
    width: 150px;
    margin: 36px auto 0px auto;
  }
`
export const Address = styled.div`
  font-weight: 500;
  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(18)};
  text-transform: uppercase;
  color: var(--lightBeige);
  margin-top: ${calculateResponsiveSize(25)};
  letter-spacing: 0.1em;
  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 18px;
    margin-top: 36px;
  }
`
export const Right = styled.div`
  flex: 1;
`
export const Logos = styled.div`
  display: flex;
  margin-bottom: ${calculateResponsiveSize(48)};
  column-gap: 5%;
  align-items: flex-end;
  ${mediaMax('mobile')} {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 36px;
    align-items: center;
  }
`
export const LogosItem = styled.div`
  width: 20%;
  ${mediaMax('mobile')} {
    width: auto;
  }
`
export const Users = styled.div`
  display: flex;
  column-gap: 2%;
  row-gap: ${calculateResponsiveSize(48)};
  ${mediaMax('mobile')} {
    flex-direction: column;
    text-align: center;
    gap: 36px;
    ${Logos} {
      margin-bottom: 0px;
    }
  }
`
export const User = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${calculateResponsiveSize(16)};
  line-height: ${calculateResponsiveSize(24)};
  /*   width: 23%;
 */
  ${mediaMax('mobile')} {
    font-size: 14px;
    line-height: 20px;
    width: auto;
  }
`
export const UsersMain = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${calculateResponsiveSize(48)};
  width: 23%;
  flex: 0 0 auto;

  ${mediaMax('mobile')} {
    width: auto;
    text-align: center;
    gap: 36px;
    ${Logos} {
      margin-bottom: 0px;
    }
  }
`
export const UsersSecondary = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 2%;
  row-gap: 0;
  ${User} {
    width: 31.33%;
  }
  ${mediaMax('mobile')} {
    flex-direction: column;
    gap: 36px;

    ${User} {
      width: 100%;
    }
  }
`

export const Name = styled.div`
  font-family: 'Mark Pro Medium';
`

export const Email = styled((props) => <a {...props} />)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
export const Phone = styled((props) => <a {...props} />)`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(36)};
  padding-top: ${calculateResponsiveSize(99)};
  ${mediaMax('mobile')} {
    gap: 36px;
    flex-direction: column;
  }
`
export const OtherLinks = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(36)};
`

export const SocialWrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(36)};
  ${mediaMax('mobile')} {
    gap: 18px;
  }
`
export const LinkStyles = css`
  font-family: 'Mark Pro Medium';
  color: var(--lightBeige);
  text-decoration: none;
  font-weight: 500;
  font-size: ${calculateResponsiveSize(15)};
  line-height: ${calculateResponsiveSize(15)};
  text-transform: uppercase;
  letter-spacing: 0.1em;
  &:hover {
    text-decoration: underline;
  }
  &.small {
    font-family: 'Mark Pro';

    font-size: ${calculateResponsiveSize(12)};
    line-height: ${calculateResponsiveSize(12)};
  }
  ${mediaMax('mobile')} {
    font-size: 15px;
    line-height: 15px;
    &.small {
      font-size: 12px;
      line-height: 12px;
    }
  }
`
export const LinkItem = styled((props) => <Link {...props} />)`
  ${LinkStyles}
`
export const ExternalLink = styled.a`
  ${LinkStyles}
`

export const Icon = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: ${calculateResponsiveSize(18)};
  height: ${calculateResponsiveSize(18)};
  &.fb {
    background-image: url(${fbIcon});
  }
  &.linkedin {
    background-image: url(${linkedinIcon});
  }
  &.instagram {
    background-image: url(${instagramIcon});
  }
  &.twitter {
    background-image: url(${twitterIcon});
  }
  ${mediaMax('mobile')} {
    width: 17px;
    height: 17px;
  }
`
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(24)};
  margin-top: ${calculateResponsiveSize(25)};
  ${mediaMax('mobile')} {
    flex-direction: column;
    gap: 24px;
    margin-top: 36px;
  }
`
export const Copyright = styled.div`
  margin-left: auto;
  font-style: normal;
  font-weight: 400;
  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(24)};
  text-transform: uppercase;
  color: var(--lightBeige);
  letter-spacing: 0.1em;
  ${mediaMax('mobile')} {
    margin-left: 0;
    font-size: 12px;
    line-height: 18px;
    white-space: break-spaces;
    text-align: center;
    span {
      display: block;
    }
  }
`
